.loadingText {
  text-anchor: middle;
  font-size: 50pt;
  font-weight: bold;

  fill: white;
  stroke-width: 1px;
  stroke: #333;

  stroke-dasharray: 200;
  stroke-dashoffset: 200;

  -webkit-animation-name: dash, pulse;

          animation-name: dash, pulse;
  -webkit-animation-duration: 1s, 0.7s;
          animation-duration: 1s, 0.7s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
          animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-delay: 0s, 3s;
          animation-delay: 0s, 3s;
  -webkit-animation-direction: normal, normal;
          animation-direction: normal, normal;
  -webkit-animation-iteration-count: 1, infinite;
          animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards, none;
          animation-fill-mode: forwards, none;
  -webkit-animation-play-state: running, running;
          animation-play-state: running, running;
}

@-webkit-keyframes dash {
  50% {
    fill: white;
  }

  to {
    stroke-dashoffset: 0;
    fill: #333;
  }
}

@keyframes dash {
  50% {
    fill: white;
  }

  to {
    stroke-dashoffset: 0;
    fill: #333;
  }
}

@-webkit-keyframes pulse {
  from {
    stroke: #333;
    fill: #333;
  }

  50% {
    stroke: #666;
    fill: #666;
  }

  to {
    stroke: #333;
    fill: #333;
  }
}

@keyframes pulse {
  from {
    stroke: #333;
    fill: #333;
  }

  50% {
    stroke: #666;
    fill: #666;
  }

  to {
    stroke: #333;
    fill: #333;
  }
}
