.loadingText {
  text-anchor: middle;
  font-size: 50pt;
  font-weight: bold;

  fill: white;
  stroke-width: 1px;
  stroke: #333;

  stroke-dasharray: 200;
  stroke-dashoffset: 200;

  animation-name: dash, pulse;
  animation-duration: 1s, 0.7s;
  animation-timing-function: ease-out, ease-in-out;
  animation-delay: 0s, 3s;
  animation-direction: normal, normal;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards, none;
  animation-play-state: running, running;
}

@keyframes dash {
  50% {
    fill: white;
  }

  to {
    stroke-dashoffset: 0;
    fill: #333;
  }
}

@keyframes pulse {
  from {
    stroke: #333;
    fill: #333;
  }

  50% {
    stroke: #666;
    fill: #666;
  }

  to {
    stroke: #333;
    fill: #333;
  }
}
